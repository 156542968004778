import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Meet The Team | Right Start | Mortgage Lender"
        description="At Right Start, we love what we do and want to help you afford the house of your dreams. Contact us today for a great loan experience!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pt-12 pb-20 md:pt-20 md:pb-32">
        <div className="container">
          <div className="mb-32">
            <header className="mb-8 max-w-[640px] md:mb-20">
              <h1>Meet the Team</h1>
              <p>
                We love what we do and want to help you afford the house of your
                dreams. We’re passionate, enthusiastic, and ready to help make
                your home loan experience great!
              </p>
            </header>

            <div className="grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-y-10 lg:grid-cols-4 lg:gap-x-6">
              {data.teamMembers.edges.map(({ node }, i) => {
                return (
                  node.headshot && (
                    <Link
                      to={`/${node.slug.current}/`}
                      className="group block rounded-xl bg-white p-6 text-center font-normal text-typography-body no-underline shadow-4xl hover:text-typography-body hover:shadow-5xl"
                      key={i}
                    >
                      <div className="mb-4 overflow-hidden">
                        <GatsbyImage
                          image={node.headshot.asset.gatsbyImageData}
                          loading="lazy"
                          className="z-0 rounded-full"
                        />
                      </div>
                      <div>
                        <div className="mb-0 font-semibold text-typography-heading">
                          {node.name}
                        </div>
                        <div className="text-sm">{node.title}</div>
                       <div className="text-sm">{node.nmls !== null && 'NMLS #'}
                        {node.nmls}</div>
                      </div>
                    </Link>
                  )
                );
              })}
            </div>
          </div>

          <div className="rounded-3xl bg-primary-900 py-20 px-4">
            <div className="mx-auto max-w-[688px] text-center">
              <h2 className="mb-4 text-white">
                The Right Start to a Better Career
              </h2>
              <p className="text-primary-50">
                Are you a skilled loan officer who is as dedicated to clients as
                we are? We’re seeking passionate people to join our team and
                would love to hear from you!
              </p>
              <ButtonSolid
                href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=6294EDB598DA7BAF2CEBDC55E8EF6722"
                outboundLink={true}
                text="Apply Today"
                altStyle={2}
                className="w-full md:w-auto"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(relativePath: { eq: "Open Graph/Facebook/Team.jpg" }) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Team.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
          title
          nmls
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 104)
            }
          }
        }
      }
    }
  }
`;

export default Page;
